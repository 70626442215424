import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchEvents, fetchVendors } from '../supabaseClient';

const isPastEvent = (date) => {
  const eventDate = new Date(date);
  eventDate.setHours(eventDate.getHours() + 3);
  const currentDate = new Date();
  return eventDate < currentDate;
};

const filterEvents = (events, searchTerm) => {
  const currentDate = new Date();
  
  return events.filter(event => {
    const eventDate = new Date(event.start_date);
    eventDate.setHours(eventDate.getHours() + 3);
    
    
    const searchLower = searchTerm.toLowerCase();
    return (
      eventDate > currentDate && (
        (event.title && event.title.toLowerCase().includes(searchLower)) ||
        (event.location && event.location.toLowerCase().includes(searchLower)) ||
        (event.event_type && event.event_type.toLowerCase().includes(searchLower)) ||
        (event.gender_type && event.gender_type.toLowerCase().includes(searchLower))
      )
    );
  }).sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
};

function Home() {
  const [searchTerm, setSearchTerm] = useState('');
  const [events, setEvents] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadEvents = async () => {
      const { data, error } = await fetchEvents();
      if (error) {
        console.error('Error fetching events:', error);
      } else {
        const upcomingEvents = data.filter(event => !isPastEvent(event.start_date));
        setEvents(upcomingEvents);
      }
    };

    const loadVendors = async () => {
      const { data, error } = await fetchVendors();
      if (error) {
        console.error('Error fetching vendors:', error);
      } else {
        setVendors(data);
      }
    };

    loadEvents();
    loadVendors();
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      const filteredEvents = filterEvents(events, searchTerm);
      const filteredVendors = vendors.filter((vendor) =>
        vendor.company_name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredEvents(filteredEvents);
      setFilteredVendors(filteredVendors);
    } else {
      setFilteredEvents([]);
      setFilteredVendors([]);
    }
  }, [searchTerm, events, vendors]);

  return (
    <div className="flex flex-col justify-start items-center w-full h-full pt-16">
      <div className="relative w-full max-w-3xl p-4">
        <div className="flex justify-center ">
          <img
            src="/images/logo_valley_tickets.png"
            alt="Event Search"
            className="w-48 object-contain"
          />
        </div>
        <input
          type="text"
          placeholder="Search for a School, Organization, Event, or City"
          className="w-full px-6 py-4 rounded-lg text-lg shadow-lg border-none focus:ring-4 focus:ring-blue-300 outline-none"
          style={{
            backgroundColor: 'white',
            fontSize: '18px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {searchTerm.length >= 2 && (
          <div
            className="absolute left-0 right-0 bg-white shadow-lg rounded-lg mt-2 z-10"
            style={{ maxHeight: '400px', overflowY: 'auto' }}
          >
            {filteredVendors.length > 0 ? (
              <>
                <div className="p-2 bg-gray-100 text-gray-600 font-semibold">
                  Schools/Organizations
                </div>
                <div className="border-b border-gray-200">
                  {filteredVendors.map((vendor) => (
                    <Link to={`/vendor/${vendor.id}`} key={vendor.id}>
                      <div className="p-4 hover:bg-blue-100 cursor-pointer">
                        <h2 className="text-lg font-bold">{vendor.company_name}</h2>
                        <p className="text-sm text-gray-600">{vendor.address}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            ) : null}

            {filteredEvents.length > 0 ? (
              <>
                <div className="p-2 bg-gray-100 text-gray-600 font-semibold">
                  Events
                </div>
                <div>
                  {filteredEvents.map((event) => (
                    <Link to={`/event/${event.id}`} key={event.id}>
                      <div className="p-4 hover:bg-blue-100 cursor-pointer">
                        <h2 className="text-lg font-bold">
                          {event.title}
                        </h2>
                        <h2 className="text-lg font-bold">
                        {event.gender_type} {event.event_type}
                        </h2>
                        <p className="text-sm text-gray-600">{event.location}</p>
                        <p className="text-sm text-gray-500">
                          {new Date(event.start_date).toLocaleString('en-US', {
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        
                          })}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <div className="p-4 text-center text-gray-500">No results found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
