import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import { supabase } from '../../supabaseClient';
import { FaCalendarAlt, FaMapMarkerAlt, FaUserFriends, FaTicketAlt, FaPlus, FaTrash, FaStripe, FaThLarge, FaList, FaEdit, FaHistory, FaChevronLeft, FaChevronRight, FaTimes, FaLink, FaPrint, FaDownload } from 'react-icons/fa';
import { deleteEvent, deleteTicket, checkStripeAccountStatus, fetchEventTypes } from '../../supabaseClient';
import Toast from '../common/Toast';
import SkeletonLoader from '../common/SkeletonLoader';
import { useNavigate } from 'react-router-dom';
import AddressAutocomplete from '../common/AddressAutocomplete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { QRCodeSVG } from 'qrcode.react';

const SPORTS_EVENTS = [
  'Basketball',
  'Football',
  'Soccer',
  'Baseball',
  'Softball',
  'Volleyball',
  'Track & Field',
  'Wrestling',
  'Tennis',
  'Golf',
  'Swimming',
  'Cross Country',
];

const EVENT_TYPES = [
  ...SPORTS_EVENTS,
  'Charity',
  'Tournament',
  'Other'
];

const GENDER_TYPES = ['Boys', 'Girls', 'Coed'];

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link', 'emoji'],
    ['clean']
  ]
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'link', 'emoji'
];

const EventCardSkeleton = () => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <div className="h-24 md:h-32 bg-gray-200 animate-pulse relative">
      <div className="absolute top-2 left-2 flex space-x-2">
        <div className="w-16 h-6 bg-gray-300 rounded-full"></div>
        <div className="w-20 h-6 bg-gray-300 rounded-full"></div>
      </div>
    </div>
    <div className="p-4 md:p-6">
      <div className="space-y-3">
        <div className="h-6 bg-gray-200 rounded w-3/4"></div>
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
          <div className="h-4 bg-gray-200 rounded w-1/3"></div>
        </div>
        <div className="flex justify-end">
          <div className="h-8 bg-gray-200 rounded w-24"></div>
        </div>
      </div>
    </div>
  </div>
);

const ShareModal = ({ event, vendorData, onClose, showToast }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPrinting, setIsPrinting] = useState(false);
  const eventUrl = `${window.location.origin}/event/${event.id}`;

  useEffect(() => {
    const logoImg = new Image();
    logoImg.onload = () => {
      setIsLoading(false);
    };
    logoImg.onerror = () => {
      setIsLoading(false); 
    };
    if (vendorData?.logoURL) {
      logoImg.src = vendorData.logoURL;
    } else {
      setIsLoading(false);
    }
  }, [vendorData?.logoURL]);

  const handlePrint = async () => {
    const qrCodeElement = document.getElementById('event-qr-code');
    if (!qrCodeElement) return;

    setIsPrinting(true);

    const loadLogo = () => {
      return new Promise((resolve) => {
        const logo = new Image();
        logo.onload = resolve;
        logo.src = vendorData?.logoURL;
      });
    };

    try {
      await loadLogo();
      await new Promise(resolve => setTimeout(resolve, 300));

      const qrCodeCanvas = qrCodeElement.querySelector('canvas');
      let qrCodeImageHTML = '';

      if (qrCodeCanvas) {
        const qrCodeImage = qrCodeCanvas.toDataURL();
        qrCodeImageHTML = `<img src="${qrCodeImage}" alt="QR Code with Logo" />`;
      } else {
        qrCodeImageHTML = qrCodeElement.outerHTML;
      }

      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Event QR Code</title>
            <style>
              body { 
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 20px;
                font-family: Arial, sans-serif;
              }
              img { margin: 20px 0; }
              .event-details {
                text-align: center;
                margin-top: 20px;
              }
            </style>
          </head>
          <body>
            <div class="event-details">
              <h2>${event.title}</h2>
              <p>${event.location}</p>
              <p>${new Date(event.start_date).toLocaleString()}</p>
            </div>
            ${qrCodeImageHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    } catch (error) {
      console.error('Error printing QR code:', error);
      showToast('Error printing QR code', 'error');
    } finally {
      setIsPrinting(false);
    }
  };

  const handleDownload = async () => {
    const qrCodeElement = document.getElementById('event-qr-code');
    if (!qrCodeElement) return;

    try {
      const logo = new Image();
      logo.crossOrigin = "anonymous";
      await new Promise((resolve, reject) => {
        logo.onload = resolve;
        logo.onerror = reject;
        logo.src = vendorData?.logoURL;
      });
      // Convert SVG to image
      const svgData = new XMLSerializer().serializeToString(qrCodeElement);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = svgUrl;
      });

      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);

      const logoSize = Math.min(canvas.width, canvas.height) / 5;
      const logoX = (canvas.width - logoSize) / 2;
      const logoY = (canvas.height - logoSize) / 2;
      ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);
      const link = document.createElement('a');
      link.download = `${event.title}-qr-code.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
      URL.revokeObjectURL(svgUrl);

    } catch (error) {
      console.error('Error downloading QR code:', error);
      showToast('Error downloading QR code', 'error');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Share Event</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes className="w-5 h-5" />
          </button>
        </div>
        
        <div className="flex flex-col items-center space-y-4">
          {isLoading ? (
            <div className="flex flex-col items-center space-y-4 py-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
              <p className="text-gray-600">Generating QR Code...</p>
            </div>
          ) : (
            <>
              <QRCodeSVG
                id="event-qr-code"
                value={eventUrl}
                size={200}
                level="H"
                imageSettings={{
                  src: vendorData?.logoURL,
                  x: undefined,
                  y: undefined,
                  height: 40,
                  width: 40,
                  excavate: true,
                }}
              />
              <p className="text-sm text-gray-600 text-center">
                Scan this QR code to share the event
              </p>
              <div className="flex space-x-6">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(eventUrl);
                    showToast('Link copied to clipboard!');
                  }}
                  className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors duration-200"
                  title="Copy Link"
                >
                  <FaLink className="w-6 h-6" />
                </button>
                <button
                  onClick={handlePrint}
                  disabled={isPrinting}
                  className="p-2 text-green-600 hover:bg-green-50 rounded-full transition-colors duration-200 relative"
                  title="Print QR Code"
                >
                  {isPrinting ? (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-green-600"></div>
                    </div>
                  ) : (
                    <FaPrint className="w-6 h-6" />
                  )}
                </button>
                <button
                  onClick={handleDownload}
                  className="p-2 text-purple-600 hover:bg-purple-50 rounded-full transition-colors duration-200"
                  title="Download QR Code"
                >
                  <FaDownload className="w-6 h-6" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const EventsTab = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [eventData, setEventData] = useState({
    title: '',
    location: '',
    start_date: '',
    max_attendees: '',
    description: '',
    event_type: '',
    gender_type: '',
    opponent: '',
    is_free: false,
    event_image: null
  });
  const [ticketData, setTicketData] = useState({
    name: '',
    price: '',
    
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editEventId, setEditEventId] = useState(null);
  const [activeView, setActiveView] = useState('grid'); // 'grid' or 'list'
  const [toast, setToast] = useState({ show: false, message: '', type: 'success' });
  const [isLoading, setIsLoading] = useState(true);
  const [vendorStripeStatus, setVendorStripeStatus] = useState(null);
  const [editingTicket, setEditingTicket] = useState(null);
  const [stripeStatus, setStripeStatus] = useState(null);
  const [isCheckingStripe, setIsCheckingStripe] = useState(true);
  const [activeTab, setActiveTab] = useState('upcoming'); // Add this state
  const [eventTypes, setEventTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 6;
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedEventForShare, setSelectedEventForShare] = useState(null);
  const [vendorData, setVendorData] = useState(null);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);
  const totalPages = Math.ceil(events.length / eventsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  const Pagination = () => (
    <div className="flex justify-center items-center space-x-4 mt-8">
      <button
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
        className={`p-2 rounded-full transition-colors duration-200 ${
          currentPage === 1
            ? 'text-gray-300 cursor-not-allowed'
            : 'text-gray-600 hover:bg-gray-100'
        }`}
        aria-label="Previous page"
      >
        <FaChevronLeft className="w-5 h-5" />
      </button>
      
      <div className="flex items-center space-x-2">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            onClick={() => setCurrentPage(index + 1)}
            className={`w-8 h-8 rounded-full ${
              currentPage === index + 1
                ? 'bg-blue-600 text-white'
                : 'text-gray-700 hover:bg-gray-100'
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      <button
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
        className={`p-2 rounded-full transition-colors duration-200 ${
          currentPage === totalPages
            ? 'text-gray-300 cursor-not-allowed'
            : 'text-gray-600 hover:bg-gray-100'
        }`}
        aria-label="Next page"
      >
        <FaChevronRight className="w-5 h-5" />
      </button>
    </div>
  );

  const fetchEvents = async () => {
    try {
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .eq('vendor_id', isAuthenticated.vendor_id)
        .eq('isDeleted', false);

      if (error) {
        console.error('Error fetching events:', error.message);
      } else {
        const currentDate = new Date();

        const upcoming = data.filter(event => {
          const eventDate = new Date(event.start_date);
          eventDate.setHours(eventDate.getHours() + 3);
          return eventDate >= currentDate;
        });
        
        const past = data.filter(event => {
          const eventDate = new Date(event.start_date);
          eventDate.setHours(eventDate.getHours() + 3);
          return eventDate < currentDate;
        });
        
        upcoming.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        past.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

        setEvents(activeTab === 'upcoming' ? upcoming : past);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkStripeSetup = async () => {
      try {
        setIsCheckingStripe(true);
        const { data: vendorData, error: vendorError } = await supabase
          .from('vendors')
          .select('stripe_account_id')
          .eq('id', isAuthenticated.vendor_id)
          .single();

        if (vendorError) throw vendorError;

        if (!vendorData.stripe_account_id) {
          setStripeStatus({ isComplete: false, message: 'Stripe account not connected' });
          return;
        }

        const status = await checkStripeAccountStatus(vendorData.stripe_account_id);
        setStripeStatus(status);

        await supabase
          .from('vendors')
          .update({ 
            stripe_account_status: status.isComplete ? 'complete' : 'incomplete' 
          })
          .eq('id', isAuthenticated.vendor_id);

      } catch (error) {
        console.error('Error checking Stripe status:', error);
        setStripeStatus({ isComplete: false, message: 'Error checking Stripe status' });
      } finally {
        setIsCheckingStripe(false);
      }
    };

    checkStripeSetup();
    fetchEvents();
  }, [isAuthenticated.vendor_id]);

  useEffect(() => {
    fetchEvents();
  }, [activeTab, isAuthenticated.vendor_id]);

  useEffect(() => {
    const loadEventTypes = async () => {
      const { data, error } = await fetchEventTypes();
      if (!error && data) {
        setEventTypes(data);
      }
    };
    loadEventTypes();
  }, []);

  useEffect(() => {
    const fetchVendorData = async () => {
      const { data, error } = await supabase
        .from('vendors')
        .select('*')
        .eq('id', isAuthenticated.vendor_id)
        .single();

      if (!error && data) {
        setVendorData(data);
      }
    };

    fetchVendorData();
  }, [isAuthenticated.vendor_id]);

  const handleSettingsNavigation = () => {
    navigate('/vendor-dashboard', { 
      state: { activeTab: 'settings' }  
    });
  };

  if (isCheckingStripe) {
    return (
      <div className="p-6">
        <div className="flex justify-center items-center min-h-[60vh]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  if (!stripeStatus?.isComplete) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[60vh] p-6">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
          <FaStripe className="text-6xl text-blue-600 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Stripe Setup Required
          </h2>
          <p className="text-gray-600 mb-6">
            Before you can create events and sell tickets, you need to complete your Stripe account setup. 
            {stripeStatus?.currentlyDue?.length > 0 && (
              <span className="block mt-2 text-yellow-600">
                There are pending requirements that need to be addressed.
              </span>
            )}
          </p>
          <button
            onClick={() => navigate('/vendor-dashboard', { 
              state: { activeTab: 'settings' }
            })}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center space-x-2 w-full"
          >
            <span>Complete Stripe Setup</span>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </button>
        </div>
      </div>
    );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
  };

  const handleTicketChange = (e) => {
    const { name, value } = e.target;
    setTicketData({ ...ticketData, [name]: value });
  };

  const handleAddEvent = async (e) => {
    e.preventDefault();
    const { title, location, start_date, max_attendees, description, event_type, gender_type, opponent } = eventData;

    try {
      // Convert local date to UTC
      const utcDate = new Date(start_date).toISOString();
      const { data: vendorData, error: vendorError } = await supabase
        .from('vendors')
        .select('short_name')
        .eq('id', isAuthenticated.vendor_id)
        .single();

      if (vendorError) throw vendorError;

      const { data, error } = await supabase
        .from('events')
        .insert([{
          vendor_id: isAuthenticated.vendor_id,
          title: isSportEvent(event_type) 
            ? `${vendorData.short_name} vs ${opponent}`
            : title,
          location,
          start_date: utcDate,
          max_attendees: parseInt(max_attendees),
          description,
          event_type,
          gender_type: isSportEvent(event_type) ? gender_type : null,
          opponent: isSportEvent(event_type) ? opponent : null,
          event_image: eventData.event_image
        }])
        .select();

      if (error) {
        showToast(error.message, 'error');
        return;
      }

      if (data && data.length > 0) {
        showToast('Event created successfully!');
        setEvents(prevEvents => [...prevEvents, data[0]]);
        closeModal();
        resetForm();

        if (eventData.is_free) {
          // Create a free ticket automatically
          const { error: ticketError } = await supabase
            .from('tickets')
            .insert([
              {
                event_id: data[0].id,
                user_id: isAuthenticated.user.id,
                name: 'Free Admission',
                price: 0,
                // valid_thru: eventData.start_date 
              }
            ]);

          if (ticketError) {
            showToast('Event created but failed to create free ticket', 'error');
            return;
          }
        }
      } else {
        showToast('Failed to create event', 'error');
      }
    } catch (error) {
      console.error('Error creating event:', error);
      showToast('Failed to create event', 'error');
    }
  };

  const formatDate = (dateString) => {

    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      
    });
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      const { error } = await deleteEvent(eventId);
      if (error) {
        showToast(error.message, 'error');
      } else {
        showToast('Event deleted successfully!');
        fetchEvents();
      }
    } catch (error) {
      showToast('Failed to delete event', 'error');
    }
  };

  const handleDeleteTicket = async (ticketId) => {
    try {
      const { error } = await deleteTicket(ticketId);
      if (error) {
        showToast(error.message, 'error');
      } else {
        setTickets(tickets.filter(ticket => ticket.id !== ticketId));
        showToast('Ticket deleted successfully!');
      }
    } catch (error) {
      showToast('Failed to delete ticket', 'error');
    }
  };

  const EventCard = ({ event }) => {
    const [soldTicketsCount, setSoldTicketsCount] = useState(null);
    useEffect(() => {
      const fetchTicketCount = async () => {
        const { count, error } = await supabase
          .from('tickets_sold')
          .select('*', { count: 'exact', head: true })
          .eq('event_id', event.id);
          
        if (!error) {
          setSoldTicketsCount(count);
        }
      };

      fetchTicketCount();
    }, [event.id]);

    return (
      <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105">
        <div className="h-24 md:h-32 bg-gradient-to-r from-blue-500 to-blue-700 flex items-center justify-center relative">
          <div className="absolute top-2 left-2 flex space-x-2">
            {SPORTS_EVENTS.includes(event.event_type) && (
              <span className="px-2 py-1 bg-white text-blue-600 rounded-full text-sm font-semibold">
                {event.gender_type}
              </span>
            )}
            <span className="px-2 py-1 bg-white text-blue-600 rounded-full text-sm font-semibold">
              {event.event_type}
            </span>
          </div>
          <FaCalendarAlt className="text-white text-3xl md:text-4xl" />
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteEvent(event.id);
            }}
            className="absolute top-2 right-2 p-2 text-white hover:text-red-500 transition-colors duration-200"
          >
            <FaTrash className="w-4 h-4" />
          </button>
        </div>
        <div className="p-4 md:p-6">
          <h3 className="text-lg md:text-xl font-bold mb-2">
            {event.title}
          </h3>
          <div className="space-y-2 text-gray-600 text-sm md:text-base">
            <div className="flex items-center">
              <FaMapMarkerAlt className="mr-2 flex-shrink-0" />
              <span className="truncate">{event.location}</span>
            </div>
            <div className="flex items-center">
              <FaCalendarAlt className="mr-2 flex-shrink-0" />
              <span>{formatDate(event.start_date)}</span>
            </div>
            <div className="flex items-center">
              <FaUserFriends className="mr-2 flex-shrink-0" />
              <span>
                {soldTicketsCount !== null ? (
                  `${soldTicketsCount} / ${event.max_attendees} tickets sold`
                ) : (
                  'Loading...'
                )}
              </span>
            </div>
            {SPORTS_EVENTS.includes(event.event_type) && (
              <div className="flex items-center">
                <FaTicketAlt className="mr-2 flex-shrink-0" />
                <span className="truncate">vs {event.opponent}</span>
              </div>
            )}
          </div>
          <div className="mt-4 flex justify-between items-center">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedEventForShare(event);
                setIsShareModalOpen(true);
              }}
              className="px-3 py-1 md:px-4 md:py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-300 text-sm md:text-base"
            >
              Share
            </button>
            <button
              onClick={() => handleEditClick(event)}
              className="px-3 py-1 md:px-4 md:py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300 text-sm md:text-base"
            >
              Edit Event
            </button>
          </div>
        </div>
      </div>
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    resetForm();
  };

  const resetForm = () => {
    setEventData({
      title: '',
      location: '',
      start_date: '',
      max_attendees: '',
      description: '',
      event_type: '',
      gender_type: '',
      opponent: '',
      is_free: false,
      event_image: null
    });
    setTicketData({
      name: '',
      price: '',
      // valid_thru: ''
    });
    setTickets([]);
    setEditEventId(null);
  };

  const handleEditClick = (event) => {
    setIsEditing(true);
    setEditEventId(event.id);
    setEventData({
      ...event,
      start_date: formatDateForInput(event.start_date)
    });
    setIsModalOpen(true);

    const fetchTickets = async () => {
      const { data, error } = await supabase
        .from('tickets')
        .select('*')
        .eq('event_id', event.id)
        .eq('isDeleted', false);

      if (error) {
        console.error('Error fetching tickets:', error.message);
      } else {
        setTickets(data || []);
      }
    };

    fetchTickets();
  };

  const handleEditEvent = async (e) => {
    e.preventDefault();
    
    const { data, error } = await supabase
      .from('events')
      .update(eventData)
      .eq('id', editEventId)
      .select();

    if (error) {
      showToast(error.message, 'error');
    } else {
      showToast('Event updated successfully!');
      fetchEvents();
      closeModal();
    }
  };

  const handleAddTicket = async (e) => {
    e.preventDefault();
    const { name, price, valid_thru } = ticketData;

    const eventId = editEventId || (events.length > 0 ? events[events.length - 1].id : null);

    if (!eventId) {
      showToast('Error: No event selected for this ticket.', 'error');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('tickets')
        .insert([
          {
            event_id: eventId,
            user_id: isAuthenticated.user.id,
            name,
            price: parseFloat(price),
            // valid_thru,
          }
        ])
        .select();

      if (error) {
        console.error('Error adding ticket:', error.message);
        showToast(error.message, 'error');
        return;
      }

      if (data && data.length > 0) {
        setTickets(prevTickets => [...prevTickets, data[0]]);
        setTicketData({ name: '', price: '', valid_thru: '' });
        showToast('Ticket added successfully!');
      } else {
        showToast('Ticket added but failed to update display', 'error');
      }
    } catch (error) {
      console.error('Error in handleAddTicket:', error);
      showToast('Failed to add ticket', 'error');
    }
  };

  const handleEditTicket = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase
        .from('tickets')
        .update({
          name: ticketData.name,
          price: parseFloat(ticketData.price),
          // valid_thru: ticketData.valid_thru
        })
        .eq('id', editingTicket.id)
        .select();

      if (error) throw error;
      setTickets(tickets.map(ticket => 
        ticket.id === editingTicket.id ? data[0] : ticket
      ));

      setTicketData({ name: '', price: '' });
      setEditingTicket(null);
      showToast('Ticket updated successfully!');
    } catch (error) {
      console.error('Error updating ticket:', error);
      showToast('Failed to update ticket', 'error');
    }
  };

  const startEditingTicket = (ticket) => {
    setTicketData({
      name: ticket.name,
      price: ticket.price,
      // valid_thru: formatDateForInput(ticket.valid_thru)
    });
    setEditingTicket(ticket);
  };

  const showToast = (message, type = 'success') => {
    setToast({ show: true, message, type });
    setTimeout(() => {
      setToast({ show: false, message: '', type: 'success' });
    }, 3000);
  };

  const isSportEvent = (eventType) => SPORTS_EVENTS.includes(eventType);

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
    return date.toISOString().slice(0, 16); // Format: "YYYY-MM-DDThh:mm"
  };

  const isPastEvent = (date) => {
    const eventDate = new Date(date);
    eventDate.setHours(eventDate.getHours() + 3);
    return eventDate < new Date();
  };

  return (
    <div className="p-4 md:p-6">
      {toast.show && <Toast message={toast.message} type={toast.type} onClose={() => setToast({ show: false, message: '', type: 'success' })} />}

    
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:justify-between md:items-center mb-6">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:space-x-4">
          <h2 className="text-2xl font-bold">Events Management</h2>
          <div className="flex bg-gray-100 rounded-lg p-1 self-start">
            <button
              onClick={() => setActiveTab('upcoming')}
              className={`px-3 py-2 md:px-4 text-sm md:text-base rounded-md transition-colors duration-200 ${
                activeTab === 'upcoming'
                  ? 'bg-white text-blue-600 shadow-sm'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              Upcoming
            </button>
            <button
              onClick={() => setActiveTab('past')}
              className={`px-3 py-2 md:px-4 text-sm md:text-base rounded-md transition-colors duration-200 ${
                activeTab === 'past'
                  ? 'bg-white text-blue-600 shadow-sm'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              Past
            </button>
          </div>
        </div>

        {/* View Toggle and Add Button */}
        <div className="flex items-center space-x-2">
          <div className="bg-white rounded-lg p-1 shadow-sm border flex items-center">
            <button
              onClick={() => setActiveView('grid')}
              className={`p-2 rounded-md transition-all duration-200 ${
                activeView === 'grid'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
              title="Grid View"
            >
              <FaThLarge className="w-4 h-4 md:w-5 md:h-5" />
            </button>
            <button
              onClick={() => setActiveView('list')}
              className={`p-2 rounded-md transition-all duration-200 ${
                activeView === 'list'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
              title="List View"
            >
              <FaList className="w-4 h-4 md:w-5 md:h-5" />
            </button>
          </div>

          {/* Add Event Button - Only show for upcoming events */}
          {activeTab === 'upcoming' && (
            <button
              onClick={() => {
                setIsEditing(false);
                setIsModalOpen(true);
              }}
              className="flex items-center px-3 py-2 md:px-4 md:py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-all duration-200 shadow-sm text-sm md:text-base"
            >
              <FaPlus className="mr-1 md:mr-2 w-4 h-4" />
              <span>Add Event</span>
            </button>
          )}
        </div>
      </div>

      {/* Empty States */}
      {!isLoading && events.length === 0 && (
        <div className="flex flex-col items-center justify-center py-12 px-4 text-center">
          <div className="bg-gray-100 rounded-full p-4 mb-4">
            {activeTab === 'upcoming' ? (
              <FaCalendarAlt className="w-8 h-8 text-gray-400" />
            ) : (
              <FaHistory className="w-8 h-8 text-gray-400" />
            )}
          </div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            {activeTab === 'upcoming' 
              ? 'No Upcoming Events' 
              : 'No Past Events'
            }
          </h3>
          <p className="text-gray-600 mb-6 max-w-sm">
            {activeTab === 'upcoming' 
              ? 'Get started by creating your first event using the "Add Event" button above.'
              : 'Past events will appear here once your upcoming events are completed.'
            }
          </p>
          {activeTab === 'upcoming' && (
            <button
              onClick={() => {
                setIsEditing(false);
                setIsModalOpen(true);
              }}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
            >
              <FaPlus className="mr-2" />
              Create First Event
            </button>
          )}
        </div>
      )}

      {events.length > 0 && (
        activeView === 'grid' ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
            {isLoading ? (
              [...Array(6)].map((_, index) => (
                <EventCardSkeleton key={index} />
              ))
            ) : (
              currentEvents.map(event => (
                <EventCard key={event.id} event={event} />
              ))
            )}
          </div>
        ) : (
          <div className="space-y-4">
            {isLoading ? (
              [...Array(4)].map((_, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                  <SkeletonLoader />
                </div>
              ))
            ) : (
              events.map(event => (
                <div key={event.id} className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="text-xl font-bold">{event.title}</h3>
                      <div className="text-gray-600 mt-2 space-y-1">
                        <div className="flex items-center">
                          <FaMapMarkerAlt className="mr-2" />
                          {event.location}
                        </div>
                        <div className="flex items-center">
                          <FaCalendarAlt className="mr-2" />
                          {formatDate(event.start_date)}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => handleEditClick(event)}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        )
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60] p-4">
          <div className="bg-white rounded-xl shadow-2xl w-full max-w-4xl max-h-[90vh] relative">
            {/* Modal Header */}
            <div className="bg-gradient-to-r from-blue-500 to-blue-700 p-6 rounded-t-xl">
              <h2 className="text-2xl font-bold text-white">
                {isEditing ? 'Edit Event' : 'Create New Event'}
              </h2>
              <button 
                onClick={closeModal} 
                className="absolute top-4 right-4 text-white hover:bg-blue-600 rounded-full p-2 transition-colors duration-200"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <form onSubmit={isEditing ? handleEditEvent : handleAddEvent}>
              <div className="modal-content p-6 relative">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className={isEditing ? 'md:col-span-1' : 'md:col-span-2'}>
                    <h3 className="text-lg font-semibold text-gray-700 border-b pb-2">Event Details</h3>
                    <div className="md:col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Event Type *</label>
                      <select
                        name="event_type"
                        value={eventData.event_type}
                        onChange={(e) => {
                          const selectedType = eventTypes.find(type => type.name === e.target.value);
                          setEventData(prev => ({
                            ...prev,
                            event_type: e.target.value,
                            event_image: selectedType ? selectedType.id : null
                          }));
                        }}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      >
                        <option value="">Select Event Type</option>
                        {eventTypes.map(type => (
                          <option key={type.id} value={type.name}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {eventData.event_type && (
                      <>
                        {SPORTS_EVENTS.includes(eventData.event_type) ? (
                          <>
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">Gender Type *</label>
                              <select
                                name="gender_type"
                                value={eventData.gender_type}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required={SPORTS_EVENTS.includes(eventData.event_type)}
                              >
                                <option value="">Select Gender Type</option>
                                {GENDER_TYPES.map(type => (
                                  <option key={type} value={type}>{type}</option>
                                ))}
                              </select>
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">Opponent *</label>
                              <input
                                type="text"
                                name="opponent"
                                value={eventData.opponent}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter opponent name"
                                required={SPORTS_EVENTS.includes(eventData.event_type)}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="md:col-span-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Event Title *</label>
                            <input
                              type="text"
                              name="title"
                              value={eventData.title}
                              onChange={handleInputChange}
                              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Enter event title"
                              required={!SPORTS_EVENTS.includes(eventData.event_type)}
                            />
                          </div>
                        )}
                        <div className="md:col-span-2">
                          <label className="block text-sm font-medium text-gray-700 mb-1">Location *</label>
                          <AddressAutocomplete
                            value={eventData.location}
                            onChange={(value) => setEventData({ ...eventData, location: value })}
                            placeholder="Enter event location"
                            required={true}
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">Start Date & Time *</label>
                          <input
                            type="datetime-local"
                            name="start_date"
                            value={eventData.start_date}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            required
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">Maximum Attendees *</label>
                          <input
                            type="number"
                            name="max_attendees"
                            value={eventData.max_attendees}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter maximum capacity"
                            required
                          />
                        </div>
                        {!isEditing && (
                          <div className="md:col-span-2 mb-4 mt-4">
                            <div className="flex items-center justify-between py-3 bg-gray-50 px-4 rounded-lg border border-gray-200">
                              <label className="text-sm font-medium text-gray-700">Free Event</label>
                              <button
                                type="button"
                                onClick={() => setEventData(prev => ({ ...prev, is_free: !prev.is_free }))}
                                className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out ${
                                  eventData.is_free ? 'bg-green-600' : 'bg-gray-200'
                                }`}
                              >
                                <span
                                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out ${
                                    eventData.is_free ? 'translate-x-6' : 'translate-x-1'
                                  }`}
                                />
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="md:col-span-2">
                          <label className="block text-sm font-medium text-gray-700 mb-1">Description *</label>
                          <div className="editor-wrapper">
                            <ReactQuill
                              theme="snow"
                              value={eventData.description}
                              onChange={(value) => setEventData({ ...eventData, description: value })}
                              modules={modules}
                              formats={formats}
                              className="h-48"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {isEditing && (
                    <div className="md:col-span-1">
                      <h3 className="text-lg font-semibold text-gray-700 border-b pb-2">Ticket Information</h3>
                      
                      <div className="space-y-4 mt-4">
                        <div className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Ticket Name</label>
                            <input
                              type="text"
                              name="name"
                              value={ticketData.name}
                              onChange={handleTicketChange}
                              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Enter ticket name"
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Price</label>
                            <input
                              type="number"
                              step="0.01"
                              name="price"
                              value={ticketData.price}
                              onChange={handleTicketChange}
                              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Enter price"
                            />
                          </div>

                          {/* <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Valid Until</label>
                            <input
                              type="datetime-local"
                              name="valid_thru"
                              value={formatDateForInput(ticketData.valid_thru)}
                              onChange={handleTicketChange}
                              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div> */}

                          <div className="flex space-x-2">
                            <button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                if (editingTicket) {
                                  handleEditTicket(e);
                                } else {
                                  handleAddTicket(e);
                                }
                              }}
                              className={`flex-1 px-4 py-2 ${
                                editingTicket 
                                  ? 'bg-yellow-500 hover:bg-yellow-600' 
                                  : 'bg-green-500 hover:bg-green-600'
                              } text-white rounded-lg transition-colors duration-200`}
                            >
                              {editingTicket ? 'Update Ticket' : 'Add Ticket Type'}
                            </button>
                            {editingTicket && (
                              <button
                                type="button"
                                onClick={() => {
                                  setEditingTicket(null);
                                  setTicketData({ name: '', price: '' });
                                }}
                                className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
                              >
                                Cancel
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="mt-4">
                          <h4 className="text-sm font-medium text-gray-700 mb-2">Added Tickets</h4>
                          {tickets.length > 0 ? (
                            <div className="space-y-2 max-h-[200px] overflow-y-auto">
                              {tickets.map(ticket => (
                                <div
                                  key={ticket.id}
                                  onClick={() => startEditingTicket(ticket)}
                                  className="bg-gray-50 p-3 rounded-lg shadow-sm flex justify-between items-center hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                                >
                                  <div>
                                    <p className="font-medium">{ticket.name}</p>
                                    <p className="text-sm text-gray-500">${ticket.price}</p>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      handleDeleteTicket(ticket.id);
                                    }}
                                    className="text-gray-500 hover:text-red-500 transition-colors duration-200"
                                  >
                                    <FaTrash className="w-4 h-4" />
                                  </button>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <p className="text-gray-500 text-center py-4">No tickets added yet</p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="border-t px-6 py-4 bg-gray-50 flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={closeModal}
                  className="px-6 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                >
                  {isEditing ? 'Save Changes' : 'Create Event'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isShareModalOpen && selectedEventForShare && (
        <ShareModal
          event={selectedEventForShare}
          vendorData={vendorData}
          showToast={showToast}
          onClose={() => {
            setIsShareModalOpen(false);
            setSelectedEventForShare(null);
          }}
        />
      )}

      {events.length > eventsPerPage && <Pagination />}

      <style>
        {`
          .editor-wrapper {
            position: relative;
            margin-bottom: 60px;
            overflow: visible;
          }
          .editor-wrapper .ql-container {
            height: 250px;
            overflow-y: auto;
          }
          .editor-wrapper .ql-toolbar {
            position: sticky;
            top: 0;
            z-index: 10;
            background: white;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
          }
          .free-event-toggle {
            position: relative;
            z-index: 20;
            background: white;
          }
          .ql-tooltip {
            z-index: 1000 !important;
            position: absolute !important;
            left: 0 !important;
            max-width: calc(100% - 20px) !important;
          }
          .ql-tooltip[data-mode="link"]::before {
            content: "Enter link URL:";
          }
          .modal-content {
            position: relative;
            overflow-y: auto;
            max-height: calc(90vh - 150px);
            z-index: 61;
          }
          .modal-content .ql-toolbar {
            position: sticky;
            top: 0;
            background: white;
          }
          .modal-footer {
            position: sticky;
            bottom: 0;
            background: white;
            border-bottom-left-radius: 0.75rem;
            border-bottom-right-radius: 0.75rem;
          }
          .ql-editor {
            overflow-y: auto;
            min-height: 250px;
            max-height: none;
          }
          .ql-container.ql-snow {
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        `}
      </style>
    </div>
  );
};

export default EventsTab;
