import React, { useState, useEffect } from 'react';
import { MdEvent, MdLocationOn } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createPaymentIntent } from '../supabaseClient';
import FreeCheckoutForm from './FreeCheckoutForm';
import CheckoutForm from './CheckoutForm';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [paymentState, setPaymentState] = useState({
    clientSecret: null,
    paymentIntentId: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Access the passed state data from EventPage
  const { event, vendor, tickets, ticketQuantities, totalAmount } = location.state || {};

  const hasPaidTickets = tickets?.some(ticket => {
    const quantity = ticketQuantities?.[ticket.id] || 0;
    return ticket.price > 0 && quantity > 0;
  });

  useEffect(() => {
    let mounted = true;

    const initializePayment = async () => {
      // Only proceed if we're on step 2 and have paid tickets
      if (currentStep !== 2 || !hasPaidTickets || !vendor?.id || !tickets || !ticketQuantities) {
        setIsLoading(false);
        return;
      }

      // Don't reinitialize if we already have a client secret
      if (paymentState.clientSecret) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);

        const paidTicketsCount = tickets.reduce((count, ticket) => {
          const quantity = ticketQuantities[ticket.id] || 0;
          return ticket.price > 0 ? count + quantity : count;
        }, 0);

        const subtotal = tickets.reduce((total, ticket) => {
          const quantity = ticketQuantities[ticket.id] || 0;
          return ticket.price > 0 ? total + (ticket.price * quantity) : total;
        }, 0);

        const baseAmount = parseFloat(subtotal.toFixed(2));


        const { clientSecret: newClientSecret, paymentIntentId } = await createPaymentIntent(
          baseAmount,
          vendor.id,
          paidTicketsCount
        );

        if (mounted) {
          setPaymentState({
            clientSecret: newClientSecret,
            paymentIntentId
          });
        }
      } catch (err) {
        console.error('Payment initialization error:', err);
        if (mounted) {
          setError('Failed to initialize payment. Please try again.');
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    initializePayment();

    return () => {
      mounted = false;
    };
  }, [currentStep, hasPaidTickets, paymentState.clientSecret, vendor?.id, tickets, ticketQuantities]); // Include necessary dependencies

  // Reset states when leaving checkout step
  useEffect(() => {
    if (currentStep !== 2) {
      setPaymentState({
        clientSecret: null,
        paymentIntentId: null
      });
      setIsLoading(false);
      setError(null);
    }
  }, [currentStep]);

  // Early return for missing data
  if (!event || !vendor || !tickets || !ticketQuantities) {
    navigate('/');
    return null;
  }

  const nextStep = () => setCurrentStep(prev => prev + 1);
  const prevStep = () => setCurrentStep(prev => prev - 1);

  return (
    <div className="max-w-3xl mx-auto">
      {/* Header with step navigation */}
      <div className="flex justify-around items-center bg-gray-100 p-4 rounded-lg">
        <StepIndicator step={1} label="Selection Summary" currentStep={currentStep} />
        <StepIndicator step={2} label="Checkout" currentStep={currentStep} />
        <StepIndicator step={3} label="Confirmation" currentStep={currentStep} />
      </div>

      {/* Step Content */}
      <div className="p-6 bg-white shadow-md rounded-lg mt-6">
        {currentStep === 1 && (
          <SelectionSummary
            event={event}
            vendor={vendor}
            ticketQuantities={ticketQuantities}
            tickets={tickets}
            calculateTotalAmount={() => totalAmount}
            nextStep={nextStep}
          />
        )}
        {currentStep === 2 && (
          <>
            {!hasPaidTickets ? (
              <FreeCheckoutForm 
                event={event}
                vendor={vendor}
                tickets={tickets}
                ticketQuantities={ticketQuantities}
                onSuccess={nextStep}
              />
            ) : (
              <>
                {isLoading ? (
                  <div className="text-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
                    <p className="mt-2 text-gray-600">Initializing payment...</p>
                  </div>
                ) : error ? (
                  <div className="text-center py-4 text-red-600">
                    {error}
                  </div>
                ) : paymentState.clientSecret ? (
                  <Elements 
                    stripe={stripePromise} 
                    options={{
                      clientSecret: paymentState.clientSecret,
                      appearance: { theme: 'stripe' }
                    }}
                  >
                    <CheckoutForm 
                      event={event}
                      vendor={vendor}
                      tickets={tickets}
                      ticketQuantities={ticketQuantities}
                      totalAmount={totalAmount}
                      paymentIntentId={paymentState.paymentIntentId}
                      onSuccess={nextStep}
                      onBack={prevStep}
                    />
                  </Elements>
                ) : null}
              </>
            )}
          </>
        )}
        {currentStep === 3 && <Confirmation />}
      </div>
    </div>
  );
};

const StepIndicator = ({ step, label, currentStep }) => {
  const isCompleted = step < currentStep;
  const isActive = step === currentStep;

  return (
    <div className="text-center">
      <div
        className={`w-10 h-10 flex items-center justify-center rounded-full mb-1 ${
          isCompleted ? 'bg-green-500' : isActive ? 'bg-blue-500' : 'bg-gray-300'
        } text-white font-semibold`}
      >
        {isCompleted ? '✔️' : step}
      </div>
      <span className="text-sm">{label}</span>
    </div>
  );
};

const SelectionSummary = ({ event, vendor, ticketQuantities, tickets, calculateTotalAmount, nextStep }) => {
  const navigate = useNavigate();
  const convenienceFee = vendor?.convenience_fee || 1.30;

  // Calculate subtotal and number of PAID tickets only
  const { subtotal, paidTicketsCount } = tickets.reduce(
    (acc, ticket) => {
      const quantity = ticketQuantities[ticket.id] || 0;
      if (ticket.price > 0) {
        return {
          subtotal: acc.subtotal + ticket.price * quantity,
          paidTicketsCount: acc.paidTicketsCount + quantity,
        };
      }
      return acc;
    },
    { subtotal: 0, paidTicketsCount: 0 }
  );

  // Calculate total fees (fee per PAID ticket only)
  const totalFees = convenienceFee * paidTicketsCount;
  const finalTotal = parseFloat((subtotal + totalFees).toFixed(2));

  const handleCancel = () => {
    navigate(`/event/${event.id}`);
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Selection Summary</h2>

      <div className="bg-blue-100 text-blue-700 p-3 rounded-md mb-4">
        Please double-check your purchase and confirm the tickets are for the desired event(s), as all sales are final.
      </div>

      {/* Event Details */}
      <div className="flex items-start mb-4">
        {vendor?.logoURL && (
          <img src={vendor.logoURL} alt={`${vendor.company_name} Logo`} className="h-12 w-12 mr-4" />
        )}
        <div>
          <h3 className="text-lg font-semibold">{event.title}</h3>
          <p className="text-gray-600">{event.gender_type} {event.event_type}</p>
          <div className="flex items-center text-gray-500 mt-1">
            <MdEvent className="mr-1" />
            <span>{new Date(event.start_date).toLocaleString('en-US', {
                  weekday: 'short',
                  month: 'short', 
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                  
                })}</span>
          </div>
          <div className="flex items-center text-gray-500 mt-1">
            <MdLocationOn className="mr-1" />
            <span>{event.location}</span>
          </div>
        </div>
      </div>

      {/* Ticket Details */}
      <div className="border-t border-b py-4">
        {tickets.map((ticket) =>
          ticketQuantities[ticket.id] > 0 && (
            <div key={ticket.id} className="flex justify-between py-2">
              <span>{ticket.name}</span>
              <div className="flex items-center">
                <span>${ticket.price.toFixed(2)} x {ticketQuantities[ticket.id]}</span>
              </div>
            </div>
          )
        )}
      </div>

      {/* Pricing Breakdown */}
      <div className="mt-4">
        {paidTicketsCount > 0 && (
          <div className="flex justify-between py-2">
            <span className="text-gray-600">
              Convenience Fee ({paidTicketsCount} paid ticket{paidTicketsCount !== 1 ? 's' : ''})
            </span>
            <span className="font-semibold">${totalFees.toFixed(2)}</span>
          </div>
        )}
        <div className="flex justify-between py-2">
          <span className="text-gray-600">Subtotal</span>
          <span className="font-semibold">${subtotal.toFixed(2)}</span>
        </div>
        <div className="flex justify-between py-2 border-t border-gray-200 pt-2 text-lg font-bold">
          <span>Total Amount</span>
          <span>${finalTotal.toFixed(2)}</span>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="mt-6 flex justify-between">
        <button 
          className="text-red-600 hover:text-red-800 transition-colors duration-200" 
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200" onClick={nextStep}>
          Checkout
        </button>
      </div>
    </div>
  );
};

const Confirmation = () => {
  const navigate = useNavigate();

  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold text-green-600 mb-4">Confirmation</h2>
      <p className="text-gray-700 mb-6">Your purchase was successful. Thank you for your order!</p>
      <button
        onClick={() => navigate('/tickets')}
        className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
      >
        View Tickets
      </button>
    </div>
  );
};

export default CheckoutPage;
