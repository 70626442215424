import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { supabase } from '../supabaseClient';
import { recordTicketSale, createUserProfile } from '../supabaseClient';

const CheckoutForm = ({ 
  event, 
  vendor, 
  tickets, 
  ticketQuantities, 
  totalAmount,
  paymentIntentId,
  onSuccess, 
  onBack 
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  });
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    // Check user authentication status
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data: userData } = await supabase
          .from('users')
          .select('first_name, last_name, email, phone_number')
          .eq('id', user.id)
          .single();

        setIsAuthenticated({ user, userData });
        if (userData) {
          setUserInfo({
            first_name: userData.first_name || '',
            last_name: userData.last_name || '',
            email: userData.email || user.email || '',
            phone: userData.phone_number || ''
          });
        }
      }
    };

    checkUser();
  }, []);

  const calculateFinalTotal = () => {
    const subtotal = tickets.reduce((total, ticket) => {
      const quantity = ticketQuantities[ticket.id] || 0;
      return ticket.price > 0 ? total + ticket.price * quantity : total; 
    }, 0);

    const paidTicketsCount = tickets.reduce((count, ticket) => {
      const quantity = ticketQuantities[ticket.id] || 0;
      return ticket.price > 0 ? count + quantity : count; 
    }, 0);

    const convenienceFee = vendor?.convenience_fee || 1.30;
    const totalFees = convenienceFee * paidTicketsCount; // Apply fees only to paid tickets
    return (subtotal + totalFees).toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      // Convert email to lowercase before submission
      const lowerCaseEmail = userInfo.email.toLowerCase();

      // First, check event capacity
      const totalTicketsRequested = Object.values(ticketQuantities).reduce((a, b) => a + b, 0);
      
      // Get current ticket count for this event
      const { count: soldTicketsCount, error: countError } = await supabase
        .from('tickets_sold')
        .select('*', { count: 'exact', head: true })
        .eq('event_id', event.id);

      if (countError) throw countError;

      // Check if adding these tickets would exceed capacity
      if ((soldTicketsCount + totalTicketsRequested) > event.max_attendees) {
        setError(`Sorry, only ${event.max_attendees - soldTicketsCount} tickets remaining for this event.`);
        setProcessing(false);
        return;
      }

      if (!stripe || !elements) {
        throw new Error('Stripe not initialized');
      }

      const { error: stripeError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/tickets`,
        },
        redirect: 'if_required',
      });

      if (stripeError) {
        throw stripeError;
      }

      // Record tickets
      const ticketsToSell = tickets.flatMap(ticket => {
        const quantity = ticketQuantities[ticket.id] || 0;
        return Array(quantity).fill().map(() => ({
          ticket_id: ticket.id,
          event_id: event.id,
          vendor_id: vendor.id,
          price_paid: ticket.price,
          ticket_type: ticket.type,
          payment_intent_id: paymentIntentId,
          user_id: isAuthenticated?.user?.id || null,
          ...userInfo
        }));
      });

      const { error: saleError, data: soldTickets } = await recordTicketSale(ticketsToSell);
      if (saleError) throw saleError;

      // Send confirmation email using Brevo
      try {
        if (!soldTickets || soldTickets.length === 0) {
          throw new Error('No ticket data available');
        }

        const response = await fetch('https://api.brevo.com/v3/smtp/email', {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'api-key': process.env.REACT_APP_BREVO_API_KEY,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            sender: {
              name: 'Valley Tickets',
              email: 'valleyticketsrgv@gmail.com'
            },
            to: [{
              email: userInfo.email,
              name: `${userInfo.first_name} ${userInfo.last_name}`
            }],
            subject: `Your tickets for ${event.title}`,
            htmlContent: `
              <h1>Thank you for your purchase!</h1>
              <p>Here ${soldTickets.length > 1 ? 'are your tickets' : 'is your ticket'} for ${event.title}:</p>
              <p><a href="${window.location.origin}/ticket/${soldTickets[0].id}" 
                style="display: inline-block; padding: 10px 20px; background-color: #3B82F6; color: white; text-decoration: none; border-radius: 5px; margin: 5px 0;">
                View ${soldTickets.length > 1 ? 'Tickets' : 'Ticket'} (${soldTickets.length} ${soldTickets.length > 1 ? 'total' : ''})
              </a></p>
              <p>Event Details:</p>
              <ul style="list-style: none; padding: 0;">
                <li>Date: ${new Date(event.start_date).toLocaleString('en-US', {
                  weekday: 'short',
                  month: 'short', 
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                  
                })}</li>
                <li>Location: ${event.location}</li>
              </ul>
              <p>Please keep this email for your records.</p>
            `
          })
        });

        if (!response.ok) {
          console.error('Failed to send email:', await response.text());
        }
      } catch (emailError) {
        console.error('Error sending confirmation email:', emailError);
      }

      onSuccess();
    } catch (error) {
      console.error('Payment error:', error);
      setError(error.message || 'Payment failed. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Back Button - Above the form */}
      <button
        type="button"
        onClick={onBack}
        className="mb-6 flex items-center text-gray-600 hover:text-gray-800 transition-colors duration-200"
      >
        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
        </svg>
        Back to Summary
      </button>

      <div className="space-y-6 mb-6">
        {/* User Information Form Fields */}
        {!isAuthenticated?.userData?.first_name && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">First Name *</label>
            <input
              type="text"
              value={userInfo.first_name}
              onChange={(e) => setUserInfo({ ...userInfo, first_name: e.target.value })}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
          </div>
        )}

        {!isAuthenticated?.userData?.last_name && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Last Name *</label>
            <input
              type="text"
              value={userInfo.last_name}
              onChange={(e) => setUserInfo({ ...userInfo, last_name: e.target.value })}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
          </div>
        )}

        {(!isAuthenticated?.userData?.email || !isAuthenticated?.user?.email) && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Email *</label>
            <input
              type="email"
              value={userInfo.email}
              onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Phone (Optional)</label>
          <input
            type="tel"
            value={userInfo.phone}
            onChange={(e) => setUserInfo({ ...userInfo, phone: e.target.value })}
            className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
          />
        </div>
      </div>

      {/* Payment Element */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">Payment Details</label>
        <div className="border rounded-lg p-4">
          <PaymentElement />
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {/* Action Buttons */}
      <button
        type="submit"
        disabled={processing || !stripe || !elements}
        className={`w-full bg-gray-800 text-white py-3 rounded-lg text-lg font-semibold hover:bg-gray-700 transition duration-300 ${
          processing ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {processing ? (
          <div className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </div>
        ) : (
          `Pay $${calculateFinalTotal()}`
        )}
      </button>
    </form>
  );
};

export default CheckoutForm; 