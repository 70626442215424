import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchEventById, fetchVendorById, fetchTicketsByEventId } from '../supabaseClient';
import Layout from '../components/Layout';
import { MdLocationOn } from 'react-icons/md';
import Spinner from '../components/common/Spinner';

const SPORTS_EVENTS = [
  'Basketball',
  'Football',
  'Soccer',
  'Baseball',
  'Softball',
  'Volleyball',
  'Track & Field',
  'Wrestling',
  'Tennis',
  'Golf',
  'Swimming',
  'Cross Country',
];

const defaultSportsImageUrl = "https://s3.amazonaws.com/com.ticketspicket.app/activity-images/basketball_Edited.jpg";
const defaultEventImageUrl = "https://www.pncfairfaxconnection.com/wp-content/themes/pnc-child/img/event_placeholder.jpg";

// Add these styles to handle rich text content
const richTextStyles = `
  .prose ul {
    list-style-type: disc;
    padding-left: 1.5em;
    margin: 1em 0;
  }
  .prose ol {
    list-style-type: decimal;
    padding-left: 1.5em;
    margin: 1em 0;
  }
  .prose p {
    margin-bottom: 1em;
  }
  .prose a {
    color: #2563eb;
    text-decoration: underline;
  }
  .prose h1 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 1em 0;
  }
  .prose h2 {
    font-size: 1.25em;
    font-weight: bold;
    margin: 1em 0;
  }
  .prose strong {
    font-weight: bold;
  }
  .prose em {
    font-style: italic;
  }
`;

function EventPage() {
  const [event, setEvent] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ticketQuantities, setTicketQuantities] = useState({});
  const { id } = useParams();
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchEventData = async () => {
      const { data: eventData, error } = await fetchEventById(id);
      
      if (error || !eventData) {
        navigate('/404');
        return;
      }

      setEvent(eventData);

      const { data: vendorData, error: vendorError } = await fetchVendorById(eventData.vendor_id);
      if (vendorError) {
        console.error(vendorError);
        return;
      }
      setVendor(vendorData);

      const { data: ticketData, error: ticketError } = await fetchTicketsByEventId(id);
      if (ticketError) {
        console.error(ticketError);
        return;
      }
      setTickets(ticketData || []);
      setTicketQuantities(ticketData.reduce((acc, ticket) => ({ ...acc, [ticket.id]: 0 }), {}));
    };

    fetchEventData();
  }, [id, navigate]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const increaseQuantity = (ticketId) => {
    setTicketQuantities({ ...ticketQuantities, [ticketId]: ticketQuantities[ticketId] + 1 });
  };

  const decreaseQuantity = (ticketId) => {
    if (ticketQuantities[ticketId] > 0) {
      setTicketQuantities({ ...ticketQuantities, [ticketId]: ticketQuantities[ticketId] - 1 });
    }
  };

  const calculateTotalAmount = () => {
    return tickets.reduce((total, ticket) => total + ticket.price * (ticketQuantities[ticket.id] || 0), 0).toFixed(2);
  };

  const handleCheckout = () => {
    closeModal();
    
    // Calculate number of paid tickets
    const paidTicketsCount = tickets.reduce((total, ticket) => {
      if (ticket.price > 0) {
        return total + (ticketQuantities[ticket.id] || 0);
      }
      return total;
    }, 0);

    navigate('/checkout', {
      state: {
        event,
        vendor,
        tickets,
        ticketQuantities,
        totalAmount: calculateTotalAmount(),
        paidTicketsCount
      },
    });
  };
  

  if (!event || !vendor) return <Spinner />;

  // First, add this helper function to safely render HTML content
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <Layout vendorData={vendor}>
      <style>{richTextStyles}</style>
      <div className="min-h-screen bg-gray-100">
        <section
          className="relative text-white p-8"
          style={{
            backgroundImage: `url(${
              event.default_image?.image_path || 
              (SPORTS_EVENTS.includes(event.event_type) ? defaultSportsImageUrl : defaultEventImageUrl)
            })`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          
          <div className="relative z-10 flex flex-col md:flex-row items-start md:items-center justify-between md:p-12 max-w-6xl mx-auto">
            <div className="flex flex-col">
              <h1 className="text-3xl font-bold">
                {SPORTS_EVENTS.includes(event.event_type) && vendor
                  ? `${vendor.short_name} vs ${event.opponent}`
                  : event.title
                }
              </h1>
              <h2 className="text-2xl font-semibold">
                {SPORTS_EVENTS.includes(event.event_type)
                  ? `${event.gender_type} ${event.event_type}`
                  : event.event_type
                }
              </h2>
              <div className="flex items-center mt-2 text-lg">
                <MdLocationOn className="mr-1" />
                <span>{event.location}</span>
              </div>
            </div>
            
            <div
              className="text-white text-center p-3 rounded-lg shadow-lg mt-4 md:mt-0"
              style={{
                backgroundColor: vendor?.primary_color || '#0000FF', 
                minWidth: '80px'
              }}
            >
              <span className="block text-sm font-semibold">
                {new Date(event.start_date).toLocaleString('en-US', { 
                  weekday: 'short',
                })}
              </span>
              <span className="block text-2xl font-bold">
                {new Date(event.start_date).toLocaleString('en-US', { 
                  day: '2-digit',
                })}
              </span>
              <span className="block text-sm font-semibold">
                {new Date(event.start_date).toLocaleString('en-US', { 
                  month: 'short',
                  
                })}
              </span>
              <span className="block text-sm font-semibold mt-1">
                {new Date(event.start_date).toLocaleString('en-US', { 
                  hour: '2-digit', 
                  minute: '2-digit',
                  hour12: true,
                
                })}
              </span>
            </div>
          </div>
        </section>

        {!SPORTS_EVENTS.includes(event.event_type) && event.description && (
          <section className="py-8 px-4">
            <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">About This Event</h2>
              <div 
                className="prose max-w-none text-gray-600"
                dangerouslySetInnerHTML={createMarkup(event.description)}
              />
            </div>
          </section>
        )}

        <section className="p-6">
          <h2 className="text-2xl font-bold mb-4">Available Tickets & Prices</h2>
          <div className="bg-white p-6 shadow-lg rounded-lg">
            {tickets.length > 0 ? (
              <>
                <ul className="divide-y divide-gray-200">
                  {tickets.map(ticket => (
                    <li key={ticket.id} className="py-4 flex justify-between items-center">
                      <span>{ticket.name}</span>
                      <span>${ticket.price}</span>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={openModal}
                  className="mt-4 w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Buy Tickets
                </button>
              </>
            ) : (
              <p className="text-center text-gray-500">No tickets available as of now</p>
            )}
          </div>
        </section>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl relative">
              <button onClick={closeModal} className="absolute top-3 right-3 text-gray-600 text-2xl font-bold">
                &times;
              </button>

              {/* Vendor Logo Section */}
              <div className="flex justify-center items-center mb-6">
                {vendor?.logoURL ? (
                  <img src={vendor.logoURL} alt={`${vendor.company_name} Logo`} className="h-16 w-16 object-contain" />
                ) : (
                  <div className="h-16 w-16 bg-gray-300 rounded-full flex items-center justify-center">
                    <span className="text-gray-600 text-lg">Logo</span>
                  </div>
                )}
              </div>

              <div className="flex flex-col md:flex-row justify-between">
                {/* Left Section - Choose Your Tickets */}
                <div className="w-full md:w-1/2 p-4 border-b md:border-b-0 md:border-r">
                  <h3 className="text-xl font-bold mb-4">Choose Your Tickets</h3>
                  {tickets.map(ticket => (
                    <div key={ticket.id} className="flex items-center justify-between mb-4">
                      <span className="font-semibold">{ticket.name}</span>
                      <span className="text-gray-600">${ticket.price}</span>
                      <div className="flex items-center">
                        <button onClick={() => decreaseQuantity(ticket.id)} className="px-2 py-1 bg-gray-300 rounded-l">-</button>
                        <span className="px-4 py-1 border">{ticketQuantities[ticket.id]}</span>
                        <button onClick={() => increaseQuantity(ticket.id)} className="px-2 py-1 bg-gray-300 rounded-r">+</button>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Right Section - Selection Summary */}
                <div className="w-full md:w-1/2 p-4">
                  <h3 className="text-xl font-bold mb-4">Selection Summary</h3>
                  {tickets.map(ticket => (
                    <div key={ticket.id} className="flex justify-between mb-2">
                      <span>{ticket.name} x {ticketQuantities[ticket.id]}</span>
                      <span>${(ticket.price * ticketQuantities[ticket.id]).toFixed(2)}</span>
                    </div>
                  ))}
                  <div className="border-t pt-4 mt-4">
                    <h4 className="font-bold text-lg">Total Amount: ${calculateTotalAmount()}</h4>
                    <button onClick={handleCheckout} disabled={Object.values(ticketQuantities).reduce((a, b) => a + b, 0) === 0} className="mt-4 w-full px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 ">
                      Checkout
                    </button>
                  </div>
                </div>
              </div>

              <button onClick={closeModal} className="mt-4 w-full px-4 py-2 bg-gray-500 text-white rounded-lg">Done</button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default EventPage;
