import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase, recordTicketSale } from '../supabaseClient';

const FreeCheckoutForm = ({ event, vendor, tickets, ticketQuantities, onSuccess }) => {
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsProcessing(true);

    try {
      // Convert email to lowercase before submission
      const lowerCaseEmail = userInfo.email.toLowerCase();

      // Calculate total tickets being requested
      const totalTicketsRequested = Object.values(ticketQuantities).reduce((a, b) => a + b, 0);
      
      // Get current ticket count for this event
      const { count: soldTicketsCount, error: countError } = await supabase
        .from('tickets_sold')
        .select('*', { count: 'exact', head: true })
        .eq('event_id', event.id);

      if (countError) throw countError;

      // Check if adding these tickets would exceed capacity
      if ((soldTicketsCount + totalTicketsRequested) > event.max_attendees) {
        setError(`Sorry, only ${event.max_attendees - soldTicketsCount} tickets remaining for this event.`);
        setIsProcessing(false);
        return;
      }

      // Record individual tickets
      const ticketsToSell = tickets.flatMap(ticket => {
        const quantity = ticketQuantities[ticket.id];
        return Array(quantity).fill().map(() => ({
          ticket_id: ticket.id,
          event_id: event.id,
          vendor_id: vendor.id,
          price_paid: 0, // Free tickets
          ticket_type: ticket.name,
          ...userInfo
        }));
      });

      const { error: saleError, data: soldTickets } = await recordTicketSale(ticketsToSell);
      
      if (saleError) throw saleError;

      // Send confirmation email using Brevo
      try {
        const response = await fetch('https://api.brevo.com/v3/smtp/email', {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'api-key': process.env.REACT_APP_BREVO_API_KEY,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            sender: {
              name: 'Valley Tickets',
              email: 'valleyticketsrgv@gmail.com'
            },
            to: [{
              email: userInfo.email,
              name: `${userInfo.first_name} ${userInfo.last_name}`
            }],
            subject: `Your tickets for ${event.title}`,
            htmlContent: `
              <h1>Thank you for your registration!</h1>
              <p>Here ${soldTickets.length > 1 ? 'are your tickets' : 'is your ticket'} for ${event.title}:</p>
              <p><a href="${window.location.origin}/ticket/${soldTickets[0].id}" 
                style="display: inline-block; padding: 10px 20px; background-color: #3B82F6; color: white; text-decoration: none; border-radius: 5px; margin: 5px 0;">
                View ${soldTickets.length > 1 ? 'Tickets' : 'Ticket'} (${soldTickets.length} ${soldTickets.length > 1 ? 'total' : ''})
              </a></p>
              <p>Event Details:</p>
              <ul style="list-style: none; padding: 0;">
                <li>Date: ${new Date(event.start_date).toLocaleString('en-US', {
                  weekday: 'short',
                  month: 'short', 
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                  
                })}</li>
                <li>Location: ${event.location}</li>
              </ul>
              <p>Please keep this email for your records.</p>
            `
          })
        });

        if (!response.ok) {
          console.error('Failed to send email:', await response.text());
        }
      } catch (emailError) {
        console.error('Error sending confirmation email:', emailError);
        // Don't fail the transaction if email fails
      }

      onSuccess();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="p-4 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">First Name *</label>
          <input
            type="text"
            value={userInfo.first_name}
            onChange={(e) => setUserInfo({ ...userInfo, first_name: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Last Name *</label>
          <input
            type="text"
            value={userInfo.last_name}
            onChange={(e) => setUserInfo({ ...userInfo, last_name: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Email *</label>
          <input
            type="email"
            value={userInfo.email}
            onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Phone (Optional)</label>
          <input
            type="tel"
            value={userInfo.phone}
            onChange={(e) => setUserInfo({ ...userInfo, phone: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      <button
        type="submit"
        disabled={isProcessing}
        className={`w-full bg-green-600 text-white py-3 rounded-lg text-lg font-semibold hover:bg-green-700 transition-colors duration-200 ${
          isProcessing ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {isProcessing ? (
          <div className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </div>
        ) : (
          'Complete Registration'
        )}
      </button>
    </form>
  );
};

export default FreeCheckoutForm; 